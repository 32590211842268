import React from 'react';
import Card from 'react-bootstrap/Card';
import { ImPointRight } from 'react-icons/im';

function AboutCard() {
  return (
    <Card className='quote-card-view'>
      <Card.Body>
        <blockquote className='blockquote mb-0'>
          <p style={{ textAlign: 'justify' }}>
            Hi Everyone, I am <span className='purple'>Ananda Aqilla Aqsa </span>
            from <span className='purple'>Medan, Indonesia.</span>
            <br />
            I am currently a fresh graduate with a D3 degree in Management Informatics (A.Md.Kom.) and am actively seeking opportunities in the IT field.
            <br />
            I have completed several projects in Web Development and UI/UX Design.
            <br />
            <br />
            Apart from coding & design, some other activities that I love to do!
          </p>
          <ul>
            <li className='about-activity'>
              <ImPointRight /> Playing Games
            </li>
            <li className='about-activity'>
              <ImPointRight /> Public Speaking
            </li>
            <li className='about-activity'>
              <ImPointRight /> Reading & Writing
            </li>
            <li className='about-activity'>
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: 'rgb(155 126 172)' }}>"Striving to be successful and continuously achieving success!" </p>
          <footer className='blockquote-footer'>Nandzz.</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
