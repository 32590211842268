import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProjectCard from './ProjectCards';
import Particle from '../Particle';
import yayasan from '../../Assets/Projects/yayasan.png';
import lensaKarya from '../../Assets/Projects/lenka.png';
import rulife from '../../Assets/Projects/rulife.png';
import lenka from '../../Assets/Projects/lenka-ui.png';
import datacenter from '../../Assets/Projects/data-center.png';

function Projects() {
  return (
    <Container fluid className='project-section'>
      <Particle />
      <Container>
        <h1 className='project-heading'>
          My Recent <strong className='purple'>Works </strong>
        </h1>
        <p style={{ color: 'white' }}>Here are a few projects I've worked on recently.</p>
        <Row style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={rulife}
              isBlog={false}
              title='RULIFE | Rumah Literasi Life Skill Desa Kwala Besar'
              description='This website serves as a platform for empowering children in the village of Kwala Besar through regular activities organized by a teaching team. Supported by internet-based technology, the website aims to improve literacy and create a proper educational environment in the digital era. The website is built using Laravel 9 and Bootstrap as its CSS framework.'
              ghLink='https://github.com/nandzzurfavboy'
              demoLink='http://rulife-desakwalabesar.org/'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={datacenter}
              isBlog={false}
              title='Reservasi Data Center | Dinas KOMINFO Provsu'
              description='This website is an application designed for Regional Government Organizations (OPD) in North Sumatra Province to facilitate data retrieval from the Data Center of the North Sumatra Provincial Communication and Informatics Agency.'
              ghLink='https://github.com/nandzzurfavboy'
              demoLink='#'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={lensaKarya}
              isBlog={false}
              title='Lensa-Karya | Project Based'
              description='LensaKarya is a digital application that provides convenience for freelance photographers to offer their services and showcase their photography works.'
              ghLink='https://github.com/nandzzurfavboy'
              demoLink='#'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={yayasan}
              isBlog={false}
              title='Yayasan Muda Satu Abdi & RUMNET | UI/UX'
              description=' This is a freelance project aimed at creating a company profile for a foundation.'
              ghLink='https://github.com/nandzzurfavboy'
              demoLink='#'
            />
          </Col>

          <Col md={4} className='project-card'>
            <ProjectCard
              imgPath={lenka}
              isBlog={false}
              title='Lensa-Karya | UI/UX'
              description='This is a series of framework designs created prior to the development of the application.'
              ghLink='https://github.com/nandzzurfavboy'
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
