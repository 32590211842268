import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SiVisualstudiocode, SiPostman, SiSlack, SiVercel, SiMacos, SiWindows11, SiWindows, SiLaragon, SiFigma, SiAdobephotoshop, SiDiscord, SiMicrosoftoffice, SiMicrosoftword, SiMicrosoftexcel, SiCanva } from 'react-icons/si';

function Toolstack() {
  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      <Col xs={4} md={2} className='tech-icons'>
        <SiWindows />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiLaragon />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiFigma />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiVercel />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiAdobephotoshop />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiDiscord />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiMicrosoftword />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiMicrosoftexcel />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <SiCanva />
      </Col>
    </Row>
  );
}

export default Toolstack;
