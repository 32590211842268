import React from 'react';
import { Col, Row } from 'react-bootstrap';

function Experience() {
  const experiences = [
    {
      title: 'Web Developer Intern',
      company: 'Dinas Komunikasi dan Informatika Provinsi Sumatera Utara',
      date: 'Jan 2024 - March 2024',
    },
    {
      title: 'Web Development',
      company: 'PT. Kinema Systrans Multimedia',
      date: 'Aug 2023 - Dec 2023',
    },
  ];

  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      {experiences.map((experience, index) => (
        <Col xs={12} md={8} className='tech-icons' key={index}>
          <div className='experience-card'>
            <h4>{experience.company}</h4>
            <h5 className='purple'>{experience.date}</h5>
            <h4>{experience.title}</h4>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Experience;
