import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CgCPlusPlus } from 'react-icons/cg';
import { DiJavascript1, DiReact, DiNodejs, DiMongodb, DiPython, DiGit, DiJava, DiLaravel, DiMysql, DiPhp, DiBootstrap, DiCss3, DiHtml5 } from 'react-icons/di';
import { SiRedis, SiFirebase, SiNextdotjs, SiSolidity, SiPostgresql } from 'react-icons/si';
import { TbBrandGolang } from 'react-icons/tb';

function Techstack() {
  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      <Col xs={4} md={2} className='tech-icons'>
        <DiHtml5 />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiCss3 />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiLaravel />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiPhp />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiMysql />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiReact />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiBootstrap />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiGit />
      </Col>
      <Col xs={4} md={2} className='tech-icons'>
        <DiJava />
      </Col>
    </Row>
  );
}

export default Techstack;
