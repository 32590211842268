import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import myImg from '../../Assets/ANANDA AQILLA AQSA_PASPHOTO.svg';
import Tilt from 'react-parallax-tilt';
import { AiFillGithub, AiOutlineTwitter, AiFillInstagram, AiOutlineMessage, AiOutlineWhatsApp } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';

function Home2() {
  return (
    <Container fluid className='home-about-section' id='about'>
      <Container>
        <Row>
          <Col md={8} className='home-about-description'>
            <h1 style={{ fontSize: '2.6em' }}>
              LET ME <span className='purple'> INTRODUCE </span> MYSELF
            </h1>
            <p className='home-about-body'>
              I fell in love with programming and design and I've learned a lot along the way🤷‍♂️
              <br />
              <br />I am proficient in languages like
              <i>
                <b className='purple'> PHP, HTML, CSS, </b> and have hands-on experience with <b className='purple'> Laravel for web development. </b>
              </i>
              <br />
              <br />
              My interests lie in creating&nbsp;
              <i>
                <b className='purple'>Web Technologies and Products </b> and also keen on exploring <b className='purple'>UI/UX Design.</b>
              </i>
              <br />
              <br />
              Whenever possible, I apply my skills to develop projects using modern tools and frameworks like
              <i>
                <b className='purple'> React.js, Tailwind CSS, and Web Design principles.</b>
              </i>
            </p>
          </Col>
          <Col md={4} className='myAvtar'>
            <Tilt>
              <img src={myImg} className='img-fluid' alt='avatar' />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='home-about-social'>
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className='purple'>connect </span>with me
            </p>
            <ul className='home-about-social-links'>
              <li className='social-icons'>
                <a href='https://github.com/nandzzurfavboy' target='_blank' rel='noreferrer' className='icon-colour  home-social-icons'>
                  <AiFillGithub />
                </a>
              </li>
              <li className='social-icons'>
                <a href='https://www.linkedin.com/in/anandaaqillaaqsa18/' target='_blank' rel='noreferrer' className='icon-colour  home-social-icons'>
                  <FaLinkedinIn />
                </a>
              </li>
              <li className='social-icons'>
                <a href='https://www.instagram.com/anandaaq.aqsa18' target='_blank' rel='noreferrer' className='icon-colour home-social-icons'>
                  <AiFillInstagram />
                </a>
              </li>
              <li className='social-icons'>
                <a href='https://wa.me/6289677275505' target='_blank' rel='noreferrer' className='icon-colour  home-social-icons'>
                  <AiOutlineWhatsApp />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
